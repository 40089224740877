#netstatsum {
  padding: 20px;
  font-weight: bold;
}

.paper {
  padding: 20px;
  text-align: center;
  color: var(--text-color);
  background-color: #212446;
  border-radius: 8px;
}

/*.metadata {
  text-align: inherit !important;
}
*/
.paper.warning {
  background-color: #212446;
}

.chart-container {
  padding-left: 20px;
  padding-bottom: 35px;
  box-sizing: border-box;
}

.network-status-summary .MuiPaper-root {
  padding: 20px;
  text-align: center;
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 174, 165, 0.7);
  box-sizing: border-box;
}

.network-status-summary .MuiPaper-root.warning {
  background-color: #212446 !important;
}


.network-status-summary .grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.network-status-summary .grid-item {
  background-color: #212446;
  padding: 20px;
  border-radius: 8px;
  color: #E9F4F4;
  box-sizing: border-box;
}

.network-status-summary .good {
  color: #00c853;
}


.white-background-input .MuiOutlinedInput-root {

  border: 0.1px solid ;
  color: var(--text-color);
  border-color: var(--text-color);
  background-color: var(--bg-color);
  /*  margin-right: 30%;*/
}

.agent-id-field {
  margin-bottom: 20px;
  color: #E9F4F4 !important;
  /*  background-color: #212446 !important;*/
}

.latency-history {
  width: 100%;
  height: 350px;
  background-color: #212446 !important;
  color: #E9F4F4 !important;
  box-sizing: border-box;
}

.latency-history .chart-container {
  width: 100%;
  height: 325px;
}

.network-status-summary .chart-container canvas {
  color: var(--text-color) !important;
}
.network-status-summary .chart-container .chartjs-render-monitor {
  color: #E9F4F4 !important;
}


.select-container {
  display: flex;
  justify-content: flex-end;
  
}

.select-container h1 {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 20px;
  color: #00AEA5;
  border: 1px solid rgba(233, 244, 244, 0.2);
  background-color: var(--bg-color);
  padding: 5px;
  border-radius: 4px;
  height: 5%;
}

select {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px;
  font-size: 16px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid rgba(233, 244, 244, 0.2);
  border-radius: 4px;
  height: 5%;
  width: 120px;
}

.network-status-summary form {
  margin-right: 50%;
  
}

.network-status-summary button {
  margin-bottom: 4%;
}

/* Connectivity Health */

.good {
  color: var(--green-color);
}

.warning-health {
  color: var(--yellow-color);
}

.need-attention {
  color: red;
}

/* Stability */

.low {
  color: #e64c4c;
}

.medium {
  color: orange;
}

.medium-high {
  color: var(--yellow-color);
}

.high {
  color: var(--green-color);
}


/* Volatility */

.low-vol {
  color: #7ce063;
}

.medium-vol {
  color: yellow;
}

.high-vol {
  color: orange;
}

.huge-vol {
  color: #e64c4c;
}


@media (max-width: 768px) {
  #netstatsum{
    font-weight: bold;
  }

  .latency-history .chart-container {
    width: 100%;
    height: 10vh;
  }
  
  .MuiGrid-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .MuiGrid-item {
    box-sizing: border-box; /* Ensures proper spacing */
  }

  .MuiGrid-container > :nth-last-child(-n+3) {
    grid-column: 1 / -1 !important; /* Make them span the entire row */
    justify-self: stretch; /* Ensure they stretch across the row */
    align-self: stretch; /* Ensure full height in the row */
  }
  .network-status-summary .MuiPaper-root {
    width: 43.5vw;
  }
  
  .network-status-summary > .MuiGrid-container > :nth-last-child(-n+3) .MuiPaper-root {
    width: 90vw !important; /* Set the width for the last three items */
  }
  .network-status-summary > .MuiGrid-container > :nth-last-child(-n+2) .MuiPaper-root {
    height: 32.5vh;
    min-height: 200px;
  }

  .MuiGrid-container > .MuiGrid-item:nth-child(7),
  .MuiGrid-container > .MuiGrid-item:nth-child(8) {
    grid-column: 1 / -1 !important; /* Span across all grid columns */
    justify-self: stretch !important; /* Ensure full horizontal stretch */
    align-self: stretch !important; /* Ensure full vertical stretch */
    min-width: 100% !important; /* Full width of container */
  }
  .network-status-summary{
    min-width: 100%;

  }
  #netstatsum {
    padding: 30px; /* Add balanced padding */
  }

  .paper {
    padding: 15px;
    text-align: center;
    font-size: 14px;
    background-color: #212446;
    border-radius: 8px;
    margin-bottom: 10px; /* Add spacing between items */
  }

  .network-status-summary .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 15px; /* Reduce gap between items */
  }

  .network-status-summary .grid-item {
    background-color: #212446;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word; /* Prevent text overflow */
  }

  .latency-history {
    height: 40vh; /* Adjust height for smaller screens */
    margin-top: 10px; /* Add spacing */
  }

  .latency-history .chart-container {
    height: 25vh;
    width: 110%;
    margin-left: -5%;
    min-height: 130px;    /* Adjust chart container height */
  }

  .select-container {
    width: 100%; /* Full width for container */
    min-width: 49vw;
    display: flex;
    flex-direction: row; /* Align elements in a row */
    align-items: center; /* Vertically center elements */
    justify-content: flex-start;
    gap: 10px; /* Add spacing between items */
     /* Allow items to wrap to the next row if needed */
  }
  
  .select-container h1 {
    font-size: 16px;
    margin: 0;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--bg-color);
    color: #00AEA5; /* Text color */
    white-space: nowrap; /* Prevent text wrapping for h1 */
  }
  
  select {
    font-size: 12px; /* Adjust font size */
    padding: 6px;
    flex: 0 1 auto; /* Allow the width to adjust dynamically */
    min-width: 20%; /* Minimum width for dropdowns */
    max-width: 45%; /* Optional: Limit maximum width */
    border-radius: 4px;
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid rgba(233, 244, 244, 0.2);
    margin-top: 0px;
    margin-left: -5px;
  }

  
  .network-status-summary form  {
    min-width: 103%; /* Form takes full width */
    margin: 10px 0; /* Add vertical spacing */
    margin-top: -3vh;

  }

  .white-background-input .MuiOutlinedInput-root{
    background-color: var(--bg-color);
    color: var(--text-color);
    width: 92.5vw;
    border-color: var(--text-color);

  }

  .white-background-input .MuiOutlinedInput-root button{
    background-color: var(--bg-color);
    color: var(--text-color);
    border-color: var(--text-color);
  }

  .network-status-summary button {
    width: 100%; /* Button stretches across container */
    font-size: 14px;
    margin: 10px 0; /* Add spacing */
  }

  .chart-container {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-bottom: 15px; /* Add spacing below chart */
  }

  .paper.warning {
    padding: 15px;
    font-size: 14px;
    background-color: #212446;
  }

  .good {
    font-size: 14px;
  }

  /* Adjust spacing and alignment for connectivity health */
  .connectivity-health {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    gap: 10px;
    margin-bottom: 10px; /* Add spacing */
  }

  /* Buttons and health indicators */
  .network-status-summary button,
  .network-status-summary .grid-item {
    border: 1px solid rgba(0, 174, 165, 0.7);
    border-radius: 6px;
    background-color: #212446;
    color: #E9F4F4;
  }

  /* Fix button width overflow issues */
  .network-status-summary .button-container {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to next row */
    gap: 10px; /* Add spacing between buttons */
  }
}
