.projects h3 {
	padding: 20px;
	margin-bottom: 4%;
}

.white-input .MuiInputBase-root{
  color: white !important;
  background-color: #323769 !important;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid #ddd !important;
}

input::placeholder,
textarea::placeholder {
  color: white !important;
}

.projects select {
  margin-bottom: 20px;
  margin-top: 10px;
  color: white !important;
}

.project-form {
  color: white !important;
}

.projects button {
  margin-bottom: 10px !important;
}

.remove-endpoint {
  color: white;
  background-color: red !important;
  margin-left: 20px;
}

.tool-section {
	padding: 10px;


}

.projects h4,h5 {
  margin-bottom: 10px !important;
}