
.team h3 {
	padding: 20px;
	margin-bottom: 4%;
  font-weight: bold;
}



.team .header-paper {
  padding: 16px;
  height: 10vh;
  background-color: var(--tertiary-color);
  color: var(--light-blue) !important;
  margin-right: 0%;
  margin-left: 0%;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;

}


.team .header-paper button{
  margin-top: -5px;
  width: 10vw;
  background-color: var(--secondary-color);
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth effects */

}


.team .header-paper button:hover {
  background-color: var(--quaternary-color); 
  transform: scale(1.03); 
  cursor: pointer; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

}

.team .header-paper h6{
  font-weight: bold;
}

.profile-paper {
  padding: 15px;
  background-color: var(--tertiary-color) !important;
  color: var(--light-blue) !important;
  text-align: center;
  border: 2px solid rgba(233, 244, 244, 0.4);
  border-radius: 8px;
  font-size: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 1); /* Subtle default shadow */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}

.profile-paper:hover {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}



.profile-paper h6{
  font-weight: bold;
}

.profile-paper button{
  margin-top: -5px;
  width: 10vw;
  background-color: #F72C5B;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth effects */

}

.profile-paper button:hover {
  background-color: #D72646; 
  transform: scale(1.03); 
  cursor: pointer; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

}

.role-select{
  width: 90%;
  height: 40px;
}

.role-select .MuiOutlinedInput-notchedOutline {
  border-color: var(--text-color); 
}

.role-select .MuiSelect-select {
  color: var(--text-color); /* Text color for selected value */
}

.role-select .MuiSvgIcon-root {
  color: var(--text-color); /* Arrow color */
}

.profile-column{
  overflow-wrap: break-word; /* Ensure long words break onto the next line */
  word-wrap: break-word;
  word-break: break-word;
}

.team-delete {
	background-color: #f74141 !important;
	color: #e0f7fa !important;
}

.team-delete:hover {
	background-color: #fa7b7b !important;
	color: black !important;
}

.team-add {
	background-color: primary !important;
	color: #e0f7fa !important;
}

.team-add:hover {
	background-color: #99d3d9 !important;
	color: black !important;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  /* Team Container */
  .team-container {
    padding: 10px;
    margin: 0 auto;
  }

  .team .header-paper {
    padding: 16px;
    background-color: var(--tertiary-color);
    color: var(--light-blue) !important;
    margin-right: 0%;
    margin-left: 0%;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    width: 80vw;
    height: 30vh;
  }

  
.team .header-paper button{
  margin-top: -5px;
  width: 100%;
  background-color: var(--secondary-color);
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth effects */

}
  /* Header Row */
  .header-row {
    margin-bottom: 15px;
  }

  .header-paper {
    background-color: #2c3e50;
    color: #e0f7fa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

 .header-grid {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping of columns */
    justify-content: space-between; /* Space out the columns evenly */
    align-items: center; /* Vertically center content */
    gap: 10px; /* Add spacing between columns */
}

  .header-column {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    background-color: var(--bg-color-two);
    color: var(--text-color);
    border-radius: 6px;
    border: 1px solid rgba(233, 244, 244, 0.4);
    white-space: nowrap;
    min-width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 40px;
    flex: 1;
  }

  .header-column:last-child {
    box-shadow: none;

    background-color: var(--tertiary-color) !important;
    
  }

  .emailColl {
    position: relative;
    background-color: var(--bg-color-two); /* Keep the background consistent */
    padding-right:102.5%; /* Add large padding on the right */
    padding-left: 102.5%;
    border: 1px solid rgba(233, 244, 244, 0.4); /* Keep the border styling */
    text-align: center;
    border-radius: 6px; /* Rounded corners */
    box-sizing: border-box; /* Ensure padding and borders are included in width */
    min-width: 100px;
}

.emailcolltext{
  margin-left: -12.5vw !important;
}



  /* Profile Row */
  .profile-row {
    margin-bottom: 15px;
  }

  .profile-paper {
    background-color: #2c3e50;
    color: #e0f7fa;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(233, 244, 244, 0.4);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .profile-grid {
    display: flex; /* Arrange items in a row */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 10px; /* Add spacing between items */
    overflow-x: auto; /* Add horizontal scrollbar if items overflow */
    padding: 10px; /* Add padding for better spacing */
    border-radius: 6px; /* Rounded corners */
  }
  
  .profile-column {
    flex: 0 0 auto; /* Prevent items from shrinking or growing */
    min-width: 100%; /* Minimum width for each column */
    text-align: center; /* Center-align content */
    font-size: 14px;
    padding: 5px;
    background-color: var(--bg-color-two); /* Background color for each column */
    color: var(--text-color); /* Text color */
    border-radius: 6px; /* Rounded corners for each column */
    border: 1px solid rgba(233, 244, 244, 0.4); /* Add subtle border */
    white-space: nowrap; /* Prevent text from wrapping */
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .emailColl2 {
    position: relative;
    background-color: var(--bg-color-two); /* Keep the background consistent */
    border: 1px solid rgba(233, 244, 244, 0.4); /* Keep the border styling */
    text-align: center;
    border-radius: 6px; /* Rounded corners */
    box-sizing: border-box; /* Include padding and border in width/height calculations */
    justify-content: center;
    align-items: center;
    display: flex; /* Ensures centering of text */
    min-width: max-content; /* Allow container to stretch based on content */
    width: auto; /* Make the width adapt to the content */
    white-space: nowrap; /* Prevent wrapping */
}


.fixforemail{
  min-width: 50% !important;
}
.emailcolltext2{

}

  .profile-column:last-child {
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid rgba(233, 244, 244, 0.4);
    background-color: var(--bg-color-two) !important;
    
  }
  /* Role Select */
  .role-select {
    width: 100%;
    font-size: 14px;
    background-color: var(--bg-color-two);
    color: var(--text-color);
    border-color: var(--text-color);
    border-radius: 6px;
    padding: 5px;
  }

  .role-display {
    font-size: 14px;
    padding: 5px;
    color: var(--text-color);
    background-color: var(--bg-color-two);
    border-radius: 6px;
    border: 1px solid rgba(233, 244, 244, 0.4);
    text-align: center;
  }

  /* Delete Button */
  .team-delete-btn {
    color: #fff !important;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    min-width:100% !important;
  }
}
