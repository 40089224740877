/* Base styles */
.mobile-app {
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.mobile-app h3 {
  margin-bottom: 4%;
  font-size: 2rem;
}

.mobile-app img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Responsive styles */
@media screen and (min-width: 768px) {
  .mobile-app img {
    margin-left: 10%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1280px) {
  .mobile-app img {
    width: 1280px;
    height: 720px;
  }
}