.chat-interface {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 95%;
  margin: 0 auto;
  background-color: #1e2130;
  position: relative;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border: 1px solid rgba(0, 174, 165, 0.7); 
  border-radius: 8px;
}

.chat-title-bar {
  position: absolute;
  top: 10px;
  left: 1rem;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #262b3e;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.chat-title, .chat-title-input {
  font-size: 18px;
  font-weight: 500;
  margin-right: 0.5rem;
  color: #ffffff;
}

.chat-title-input {
  border: none;
  outline: none;
  background: transparent;
  color: #ffffff;
}

.edit-title-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.edit-title-btn svg {
  width: 18px;
  height: 18px;
  color: #00e5ff;
}

.edit-title-btn:hover svg {
  color: #00b8d4;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  background-color: #262b3e;
  border-radius: 5px;
}

.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.message-container.user {
  align-self: flex-end;
  text-align: right;
}

.message-container.assistant {
  align-self: flex-start;
}

.message-header {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 0.9em;
  color: #00e5ff;
}

.message {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  max-width: 80%;
  word-wrap: break-word;
  width: 45%;
}

.message-container.user .message {
  background-color: #3a4663;
  border: 1px solid #00e5ff;
  align-self: flex-end;
}

.message-container.assistant .message {
  background-color: #2e3446;
  border: 1px solid #7e57c2;
  align-self: flex-start;
}

.chat-input-form {
  display: flex;
  padding: 1rem;
  background-color: #262b3e;
  border-top: 1px solid #3a4663;
}

.chat-input-field {
  flex-grow: 1;
  padding: 0.75rem;
  border: 1px solid #3a4663;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  background-color: #1e2130;
  color: #ffffff;
}

.chat-send-button {
  background-color: #00e5ff;
  color: #1e2130;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-send-button:disabled {
  background-color: #3a4663;
  cursor: not-allowed;
}

.chat-send-button svg {
  width: 20px;
  height: 20px;
  fill: #1e2130;
}
