.agent-form {
  /*  background-color: #0e1a2b;*/
  color: #d8e6f3;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 50px;
}

input {
  max-width: 80%;
}

.agent-form label {
  text-align: center;
}

.agent-form input {
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d8e6f3;
  background-color: var(--tertiary-color);
  color: var(--text-color);
  font-weight: bold;
}


.agent-form h4 {
  color: var( --secondary-color);
  margin-top: 20px;
  font-size: 20px;
}

.agent-form select,
.agent-form button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #d8e6f3;
  background-color: #1a2b3c;
  color: #d8e6f3;
}

.agent-form button {
  background-color: #007bff;
  color: #ffffff;
}

.agent-form label {
  display: block;
  margin-bottom: 0px;
}

.agent-form .button-submit {
  width: 15vw;
  font-size: 20px;
  margin-top: 10px;
  background-color: var(--secondary-color);
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  font-weight: bolder;
}

.agent-form button:hover {
  background-color: var(--quaternary-color); 
  transform: scale(1.03); 
  cursor: pointer; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-color: #61aabb;
}

.formBoxTop {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(233, 244, 244, 0.4);
  color: var(--text-color) !important;
  display: flex;
  margin-right: 20px;
  justify-content: space-evenly;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.mid {
  display: flex;
  justify-content: space-evenly;
}

.formBox {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(233, 244, 244, 0.4);
  color: var(--text-color) !important;
  display: inline-block;
  margin-right: 20px;
  text-align: center;
}

.formBoxA {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(233, 244, 244, 0.4);
  color: var(--text-color) !important;
  display: inline-block;
  margin-right: 20px;
  width: 80%;
}

.combobox-container {
  position: relative;
  width: 300px;
}

.combobox-input {
  width: 100%;
  padding: 10px 35px 10px 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  border: 1px solid #d8e6f3;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #1a2b3c;
  color: #ffffff;
  appearance: none;
}

.combobox-container::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  color: #d8e6f3;
  pointer-events: none;
  font-size: 12px;
}

.combobox-options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 1000;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  background-color: #1a2b3c;
  border: 1px solid #d8e6f3;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.combobox-options li {
  padding: 8px 15px;
  cursor: pointer;
  color: #d8e6f3;
}

.combobox-options li:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.combobox-options li:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.combobox-options li:hover {
  background-color: #323769;
}

@media (max-width: 768px) {
  .agent-form {
    padding: 15px;
    padding-bottom: 30px;
  }

  .formBoxTop {
    min-height: 500px;
    display: flex;
    flex-direction: column;
  }

  .formBox {
    min-width: 85%;
    margin-top: 280%;
    margin-left: -100%;
  }
  .formBoxA {
    height: 1000px;
    min-width: 85%;
  }
  

  input,
  .agent-form select,
  .agent-form button {
    max-width: 100%;
    width: 100%; /* Full width for smaller screens */
    font-size: 16px; /* Slightly larger for better readability */
  }

  .agent-form h4 {
    font-size: 18px;
  }

  .agent-form .button-submit {
    width: 95%; /* Adjust button width */
    font-size: 18px;
  }

  .combobox-container {
    width: 100%; /* Full width combobox */
  }

  .combobox-input {
    font-size: 16px;
  }

  .combobox-options li {
    font-size: 14px;
    padding: 10px;
  }
}
.configHeader {
  padding: 0px;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
}
