/* theme.css */
:root {
    --bg-color: #212446;
    --btn-color: #1a1e33;
    --bg-color-two: #181a1b;
    --text-color: #ffffff;
    --accent-color: #00AEA5;
    --secondary-color: #00bcd4;
    --secondary-color-transparent: rgba(0, 188, 212, 0.8);
    --error-color: #cc0000;
    --light-text-color: #d3d3d3;
    --tertiary-color: #323769;
    --quaternary-color: #008C86;
    --dark-grey: #2b2e4a;
    --light-blue: #e0f7fa;
    --shadow: rgba(0, 0, 0, 0.1);
    --second-shadow: rgba(0, 174, 165, 0.7);
    --fresh-link: #00BFFF;
    --contrast: #FFD700;
    --yellow-color: #FFEB3B;
    --green-color: #66BB6A;
    --grey: #a0a0a0;
}

.light-theme {
    --bg-color: #f7f5f9;
    --btn-color: #e2dde8;
    --bg-color-two: #f3f1f5;
    --text-color: #171D1C;
    --accent-color: #76ABAE;
    --secondary-color: #0d7b83;
    --secondary-color-transparent: rgba(13, 123, 131, 0.6); 
    --error-color: #cc0000;
    --light-text-color: #222831;
    --tertiary-color: #EEEEEE;
    --quaternary-color: #008C86;
    --dark-grey: #dec3e9;
    --light-blue: #074148;
    --shadow: rgba(0, 0, 0, 0.1);
    --second-shadow: rgba(0, 174, 165, 0.3);
    --fresh-link: #007BFF;
    --contrast: #FFD700;
    --yellow-color: #FFC107;
    --green-color: #4CAF50;
    --grey: #808080;
}