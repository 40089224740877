.terminalId {
	padding: 5px;
	font-size: 16px;
	color: #E9F4F4;
	border-radius: 8px;
}

.terminalId h3 {
	padding: 20px;
	margin-bottom: 4%;
}

.agent-paper {
	color: #e0f7fa !important;
	border: 2px solid rgba(233, 244, 244, 0.4);
	margin-left: 10%;
	margin-right: 10%;
	padding: 15px;
	text-align: center;
	background-color: #323769 !important;
	border-radius: 8px;
}



button {
	background-color: #00bcd4;
	color: white;
}

