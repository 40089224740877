.regiComponent input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    width: 100%;
    max-width: 386px;
    padding: 6px;
    border: 1px solid var(--light-text-color);
    border-radius: 4px;
    background-color: var(--bg-color);
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.regiComponent select {
    margin-top: 0;
    margin-bottom: 0.5rem;
    width: 400px;
}

select {
    border: 1px solid var(--light-text-color);
    border-radius: 4px;
}

.regiComponent {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--bg-color);
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.regiBox {
    background-color: var(--tertiary-color);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px var(--shadow);
    max-width: 400px;
    width: 100%;
}

.regiComponent h1 {
    color: var(--light-blue);
    text-align: center;
    margin-bottom: 1.5rem;
}

.regiComponent form {
    display: flex;
    flex-direction: column;
}

.regiComponent label {
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.regiComponent button {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.licenseButton {
    margin: 0.5rem;
}

.regiComponent button:hover {
    background-color: var(--text-color);
    color: var(--accent-color);
}

.regiComponent span {
    color: var(--error-color);
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

a:link {
    color: var(--fresh-links);
}

a:visited {
    color: var(--light-text-color);
}

a:hover {
    color: var(--text-color);
}

a:active {
    color: var(--contrast);
}

.subscriptionTable {
    width: 60%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 2px solid var(--text-color);
    border-radius: 8px;
    box-shadow: 0 0 15px var(--shadow);
}

.subscriptionTable th,
.subscriptionTable td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid var(--light-text-color);
}

.subscriptionTable th {
    color: var(--text-color);
    font-weight: bold;
}

.subscriptionTable tr:last-child td {
    border-bottom: none;
}

.perkList {
    list-style-type: none;
    text-align: left;

    li {
        margin-bottom: 5px;
    }
}



.pricing-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    min-height: 100vh;
    background-color: var(--bg-color) !important;
}

.pricing-card h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: var(--light-blue);
}

.subtitle {
    text-align: center;
    color: var(--light-blue);
    margin-bottom: 2rem;
}

/* Pricing cards container */
.pricing-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

/* Individual pricing card */
.pricing-card {
    background-color: var(--tertiary-color);
    border-radius: 10px;
    padding: 2rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px var(--shadow);
    border: 2px solid var(--second-shadow);
}

.pricing-card:hover {
    transform: translateY(-10px);
}

.pricing-card h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--light-blue);
}

.price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--accent-color);
}

.duration {
    color: var(--grey);
    margin-bottom: 1rem;
}

/* Feature list */
.pricing-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
    text-align: left;
}

.pricing-card li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;
}

.pricing-card li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--accent-color);
}

/* Button styles */
.pricing-card button {
    background-color: var(--accent-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pricing-card button:hover {
    background-color: var(--quaternary-color);
}

/* Responsive design */
@media (max-width: 768px) {
    .pricing-cards {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 100%;
        max-width: 300px;
    }

    .regiBox {
        background-color: var(--tertiary-color);
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 6px var(--shadow);
        max-width: 325px;
        width: 80%;
    }

    .regiComponent button {
        width: 105%; /* Adjust button width for smaller screens */
        max-width: 500px; /* Limit the maximum width */
        margin: 0 auto; /* Center the button */
    }
}


