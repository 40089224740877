.training h3 {
  font-weight: bold;
}

.training h4 {
  color: #00AEA5;
  text-align: center;
  font-size: 34px;
}

.training video {
  margin-left: 15vw;
  margin-right: 10vw;
  width: 50vw;
}

@media (max-width: 768px) {
.training video{ 
  margin-left: 0;
  margin-right: 0;
  width: 90vw;
  height: 30vh;
}

.training h4{
  margin-bottom: 0;
}
}


