.detailed-latency h3 {
	padding: 20px;
  font-weight: bold;
}

.recharts-surface .recharts-layer.recharts-pie path {
  fill: var(--light-text-color) !important;
}

.detailed-latency .MuiPaper-root {
  padding: 20px;
  text-align: center;
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 174, 165, 0.7);
  box-sizing: border-box;
}


@media (max-width: 768px) {
  .detailed-latency h3 {
    margin-top: 3vh;
  }

  .detailed_latency_container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important; /* Flexible column layout */
    gap: 20px !important; /* Spacing between grid items */
    width: 100% !important; /* Ensure full width */
    height: auto !important; /* Adjust height as needed */
  }

  .detailed_latency_container > .MuiGrid-item  {
    min-width: 95vw;
    height: 30vh;
    margin-bottom: 10vh;
  }
 
  .detailed_latency_container > .MuiGrid-item:first-of-type {
    margin-bottom: 5vh;
  }
  .detailed_latency_container > .MuiGrid-item:nth-child(2n) {
    height: 27.5vh; /* Set the height for every second grid item */
    align-items: center; /* Horizontally center the content */
    justify-content: center; /* Vertically center the content */
    overflow: hidden; /* Prevent content overflow */
  }
  
  .detailed_latency_container > .MuiGrid-item:nth-child(2n) .network-status-summary {
    height: 100% !important; /* Ensure the child content fills the container */
    width: 100% !important; /* Ensure the child content spans the full width */
    display: flex; /* Use flexbox for inner alignment */
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
  }
  
  .detailed_latency_container > .MuiGrid-item:nth-child(2n) .recharts-responsive-container {
    height: 90% !important; /* Ensure the chart takes up most of the container */
    width: 95% !important; /* Add some padding inside the container */
    max-height: 30vh; /* Ensure it doesn't exceed the container's height */
    max-width: 100%; /* Prevent width overflow */
  }
  
 .detailed_lat_chart {
    height: 30vh !important; /* Adjust height for small screens */
    margin-top: 10px !important; /* Ensure margin is applied */
  }

  .detailed_latency_container .detailed_lat_chart > .detailed-latency .MuiPaper-root.network-status-summary {
    height: 35vh !important; /* Reduce height specifically */
    min-height: 20vh !important; /* Ensure it doesn’t shrink below this */
    overflow: hidden; /* Prevent content overflow */
  }
  
}