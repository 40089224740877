.throughput h3 {
	padding: 20px;
  font-weight: bold;
}

.throughput .MuiPaper-root {
  padding: 20px;
  text-align: center;
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 174, 165, 0.7);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .throughput h3 {
    margin-top: 5vh;
  }

  .select_box_Throuput {
    margin-bottom: 2vh; 
  }

  .grid_Throuput.MuiGrid-container.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    min-width: 98vw; /* Full screen width */
    margin: 0; /* Remove any spacing or margins */
    padding: 0; /* Remove padding if necessary */
  }

}
