body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: var(--light-text-color);
}

button {
  background-color: var(--secondary-color);
  border-radius: 5px;
  border-color: var(--secondary-color);
  border: none;
  transition: all 0.3s ease;

}

.agentButton {
  padding: 10px 20px;
}

nav {
  width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--bg-color);
  padding-top: 15px;
  box-sizing: border-box;
  border-right: 2px solid var(--accent-color);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  scroll-behavior: smooth;
  z-index: 100;
}

.nav-header {
  display: flex;
  align-items: center;
  cursor: pointer; /* Make the header clickable */
  font-size: 16px; /* Adjust font size */
  padding: 10px 15px;
  background-color: var(--btn-color);
  border-radius: 5px;
  color: var(--light-text-color, #ffffff);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-header:hover {
  background-color: var(--secondary-color, #007bff);
  color: var(--bg-color, #ffffff);
  box-shadow: 0 0 5px #000000; 
 
}


.arrow {
  display: inline-block;
  margin-right: 10px;
  
  transition: transform 0.3s ease;
}

.arrow.expanded {
  transform: rotate(90deg);
}

.nav-links {
  list-style: none; 
  padding-left: 15px; 
  margin: 10px 0 0; 
}
.nav-links.show {
  display: block; /* Show when expanded */
}
.nav-links li {
  margin: 15px 0; /* Add spacing between links */
}
/* Scrollbar styling for Webkit-based browsers */
nav::-webkit-scrollbar {
  width: 6px; /* Set the scrollbar width */
}

.nav-links li a {
  color: var(--light-text-color, #ffffff); /* Link text color */
  text-decoration: none; /* Remove underline */
  padding: 10px 7.5px; /* Add padding for better click area */
  display: block; /* Block-level links */
  margin-bottom: -10px;
  margin-left: 20px;
}

.nav-links li a:hover {
  border-radius: 5px; /* Add rounded corners on hover */
}

nav::-webkit-scrollbar-track {
  background: #1a1e33; /* Track background color */
}

nav::-webkit-scrollbar-thumb {
  background-color: var(--accent-color, #00d4d4); /* Thumb color */
  border-radius: 3px; /* Rounded corners */
}

nav::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color, #007bff); /* Darker thumb on hover */
}
.homepagebtn {
  display: flex;
  align-items: center; 
  justify-content: center; 
  text-decoration: none; /* Remove underline for links */
  background-color: transparent; /* Set the background to transparent */
  color: inherit; /* Use the default text color */
  padding: 15px; /* Add some padding for spacing */
  margin-bottom: 20px; /* Add spacing below the button */
  border: none; /* Remove any borders */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: all 0.3s ease; /* Add a smooth transition */
}

.homepagebtn:hover {
  background-color: transparent; /* Highlight background on hover */
  margin-left: 0px;
  margin-right: 0px;
}
.LogoForNav {
  width: 80px;
  height: auto; 
  display: block; 
  margin: 0 auto; 
}

.NavLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  background-color: var(--bg-color); 
  padding: 10px; 
 
}

.NavLogoText {
  font-size: 20px;
  color: var(--light-text-color, #ffffff); 
  margin-right: 10px; 
  font-weight: bold; 
  letter-spacing: 1px; 
}

/* Scrollbar styling for Firefox */
nav {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: var(--accent-color, red) var(--btn-color); /* Thumb and track colors */
}
.nav-content {
  flex-grow: 1;
  /*  padding-top: 15px;*/
  padding-bottom: 20px;
}

.nav-footer {
  padding: 10px;
  border-top: 1px solid var(--accent-color);
}

.user-name {
  color: var(--light-text-color);
  font-size: 16px;
  text-align: left;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 32px;
  height: 32px;
  color: var(--text-color);
  background-color: var(--light-text-color);
  border-radius: 20px;
  border: 2px solid var(--light-text-color);
  margin-left: 5px;
}

.logout-button {
  display: flex;
  align-items: center; /* Vertically align icon and text */
  justify-content: flex-start; /* Align items to the left */
  width: 100%; /* Full width */
  padding: 15px 15px; /* Adjust padding for better spacing */
  color: var(--light-text-color, #000000); /* Text color */
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  border-radius: 3px; /* Optional: Add rounded corners */
  font-size: 16px; /* Adjust text size */
  cursor: pointer; /* Show pointer on hover */
  transition: background-color 0.4s ease; /* Smooth transition for hover */
}



.logout-button .logout-icon {
  font-size: 18px; /* Icon size */
  margin-right: 10px; /* Spacing between icon and text */
  color: var(--light-text-color, #000000); /* Icon color */
}

.logout-button:hover {
  background-color: var(--secondary-color-transparent)!important;
}

nav a {
  display: block;
  color: var(--light-text-color);
  padding: 5px 20px;
  text-decoration: none;
  margin-left: 5px;
  font-size: 15px;
}

nav h1 {
  display: block;
  color: var(--light-text-color);
  padding: 5px 20px;
  text-decoration: none;
  border-color: var(--secondary-color);
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
  margin-left: 10px;
  margin-right: 40px;
  margin-top: 5px;
}

nav a:hover {
  background-color: var(--secondary-color);
  color: var(--bg-color);
  margin-right: 10px;
  margin-left: 5px;
  border-radius: 10px;
  font-weight: bold;
}

.app-container {
  position: relative;
  overflow: hidden;
}

.main-content {
  margin-left: 250px;
  padding: 20px;
  box-sizing: border-box;
}

.page-heading {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--secondary-color);
  font-size: 24px;
  text-align: center;
}

.dashboard {
  padding: 20px;
}

/*.logoutButton {
  position: absolute;
  top: 38px;
  right: 10px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}*/

.langButtons {
  position: absolute;
  top: 10px;
  right: 10px;

  button {
    background-color: var(--bg-color) !important;
    border: none;
    color: var(--secondary-color);
  }

  button:hover {
    color: var(--text-color);
    background-color: var(--secondary-color-transparent)!important;

  }
}

.feature-box{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
/* Language Button Container */
.language-button-container {
  position: relative;
}

/* Main Language Button */
.language-button {
  background-color: transparent;
  color: var(--light-text-color);
  border: none;
  border-radius: 3px;
  padding: 5px;
  font-size: 22px;
  cursor: pointer;
  margin-left: 3px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
  
}


/* Language Options */
.language-options {
  display: flex;
  position: absolute;
  left: 110%; /* Position to the right of the main button */
  top: 3px;
  flex-direction: column; /* Buttons in a horizontal row */
  gap: 5px; /* Space between buttons */
  background-color: var(--bg-color);
  padding: 0px;
  z-index: 10; /* Ensure it appears above other elements */
  width: 185px;
  height: 100px;
  flex-wrap: wrap;
  gap: 10px;
  opacity: 1;
  transition: max-height 0.4s ease, opacity 0.4s ease, padding 0.4s ease;
}

/* Individual Language Buttons */
.language-options button {
  background-color: var(--btn-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 3px;
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid var(--accent-color, #00d4d4);
  height: 35px;
}

.language-options button:hover {
  background-color: var(--secondary-color, #007bff);
  color: #ffffff;
}

/* Hide the language options by default */
.language-options.hidden {
  max-width: 0px;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;

}

/* Toggle container */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between icons */
  width: 45px; /* Adjusted for icons */
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 6.5px; /* Adjust spacing */
  padding: 0 5px; /* Add padding for icons */
}

/* Toggle handle */
.toggle-handle {
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 2; /* Ensure it overlays icons */
}

/* Icons */
.toggle-icon {
  font-size: 18px;
  color: #000;
  z-index: 1;
  transition: color 0.3s ease;
}

.sun-icon {
  color: #ffd700; /* Sun color */
}

.moon-icon {
  color: #ffffff; /* Moon color */
}

/* Active state (dark mode) */
.toggle-container.active {
  background-color: #555;
}

.toggle-container.active .toggle-handle {
  transform: translateX(25px); /* Shift to the right */
}

.toggle-container.active .sun-icon {
  color: #999; /* Dim the sun */
}

.toggle-container.active .moon-icon {
  color: #ffd700; /* Highlight the moon */
}

.hamburger-menu{
  display: none;
}

.removeNav{
  display: none;
}


@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }

  .hamburger-menu{
    position: fixed;
    z-index: 101;
    width: 50px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: block;
  }
}
