body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--bg-color-two);
}

.app {
  display: flex;
}

.main-content {
  flex: 1;
  /*  padding: 20px;*/
}