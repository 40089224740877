.lifbe h3 {
	padding: 20px;
  font-weight: bold;
}

.lifbe .MuiPaper-root {
  padding: 20px;
  text-align: center;
  font-weight: bold;
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 174, 165, 0.7);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .lifbe h3 {
    margin-top: 5vh;
  }

  .select_box_Throuput {
    margin-bottom: 2vh; 
  }


  .grid_Lifbe.MuiGrid-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
  }

  .grid_Lifbe.MuiGrid-container.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    min-width: 100%; /* Full container width */
    margin: 0; /* Remove extra margins */
    padding: 10px; /* Optional: Add spacing inside items */
    box-sizing: border-box; /* Ensure padding doesn't break layout */
    margin: 0; /* Remove any spacing or margins */
    padding: 0; /* Remove padding if necessary */
  }


  .grid_Lifbe.MuiGrid-container.MuiGrid-spacing-xs-2 > .MuiGrid-item:nth-of-type(3) {
    min-width: 95vw; /* Full container width */
  }

.select_box_Lifbe{
  margin-bottom: 2vh;
}

}
