.reflectors-container {
  padding: 20px;
}

.page-heading {
  color: white;
  margin-bottom: 30px;
  padding: 20px;
  font-weight: bold !important;
}

.table-container {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(233, 244, 244, 0.4);
  overflow-x: auto;
}

.reflectors-table {
  width: 100%;
  border-collapse: collapse;
  color: var(--text-color);
  
}

.reflectors-table th {
  background-color: var(--bg-color-two);
  padding: 15px;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid rgba(233, 244, 244, 0.2);
}

.reflectors-table td {
  padding: 15px;
  border-bottom: 2px solid rgba(163, 163, 163, 0.2);
}
.reflectors-table thead{
  font-size: 18px;
}
.reflectors-table tr:last-child td {
  border-bottom: none;
}

.reflectors-table tr:hover {
  background-color: var(--accent-color);
  transition: background-color 0.5s ease;
}

@media (max-width: 768px) {

  .page-heading{
    margin-top: 2rem !important;
  }
  .reflectors-table {
    font-size: 14px;
  }
  
  .reflectors-table th,
  .reflectors-table td {
    padding: 10px;
  }

  .table-container {
    background-color: var(--bg-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(233, 244, 244, 0.4);
    overflow-x: auto;
    width: 80vw;
    margin-left: -5vw;
  }

}