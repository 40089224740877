.comparison .page-heading {
	padding: 20px;
}

.white-background-input .MuiOutlinedInput-input::placeholder {
  color: var(--text-color) !important; /* Apply the text color from the CSS variable */
}

.comparison .paper {
  padding: 20px;
  text-align: center;
  color: #E9F4F4;
  background-color: #212446;
  border-radius: 8px;
  border: 1px solid rgba(0, 174, 165, 0.7); 
}

.search_comparison_btn{
  background-color: var(--secondary-color) !important;  
  color: #fff;
  padding: 10px 15px !important;
  border: none;
  border-radius: 4px !important;
  cursor: pointer;
  margin-right: 10px;
  font: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease !important;
  width: 10rem;
  height: 3rem;
}

.search_comparison_btn:hover {
  background-color: var(--quaternary-color) !important; 
  transform: scale(1.03) !important; 
  cursor: pointer !important; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1) !important;
}


@media (max-width: 768px) {
 .AgentComparisonID{
  font-weight: bold !important;
  margin-top: 1rem !important;
  font-size: 28px !important;

 }

 .compItem{
  max-width: 100% !important;
 }

 .compVol{
  margin-top: -200% !important;
 }

 .compconnect{
  margin-top: 35% !important;
 }

}

