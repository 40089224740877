.terminal {
  width: 100%;
  height: 100%;
}

.terminal-window {
  width: 96%;
  height: 820px;
  margin: 20px auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(233, 244, 244, 1);
  border: 1px solid #444;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.terminal-header {
  background-color: #2C2C2C;
  padding: 10px;
  display: flex;
  align-items: center;
}

.latence-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.terminal-buttons {
  display: flex;
  gap: 5px;
}

.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.close {
  background-color: #ff5f56;
}

.minimize {
  background-color: #ffbd2e;
}

.maximize {
  background-color: #27c93f;
}

.terminal-title {
  flex-grow: 1;
  text-align: center;
  color: #E9F4F4;
  font-family: 'Monaco', 'Courier', 'monospace';
  font-size: 20px;
}

.terminal-content {
  background-color: #1E1E1E;
  flex-grow: 1;
  display: flex;
  height: 100%;
/*  margin-bottom: 20px;*/
  padding-bottom: 10px;
}

.terminal.xterm {
  background-color: #1E1E1E !important;
  color: #FFFFFF !important;
  width: 100%;
  height: 100%;
}

.terminal .xterm-viewport {
  background-color: #1E1E1E !important;
}

.terminal .xterm-screen {
  color: #FFFFFF !important;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.terminal .xterm-helper-textarea {
  background-color: #1E1E1E !important;
  color: #FFFFFF !important;
}

.xterm .xterm-rows {
  color: #FFFFFF !important;
  margin-left: 2%;
  margin-right: 2%;
}

.xterm .xterm-scroll-area {
  margin-top: 0 !important;
}

.xterm-viewport, .xterm-rows {
  padding: 10px !important;
}

.terminal {
  border-radius: 4px !important;
}

.terminal .xterm-cursor {
  background: #FFFFFF !important;
}
