.homepage img {
  width: 600px;
  display: block;
  margin-left: 29%;
}

.homepage h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--accent-color);
  font-size: 70px;
  text-align: center;
}


@media (max-width: 768px) {
  .homepage img {
    margin-top: 0;
    width: 90%; /* Make the image responsive */
    margin-left: 5%; /* Center the image on smaller screens */
  }

  .homepage h2 {
    font-size: 40px; /* Adjust font size for smaller screens */
    margin-top: 25vh; /* Adjust spacing */
    margin-bottom: 15px;
  }
}