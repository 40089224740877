.report {
  min-height: 100vh;
}

.report h3 {
  padding: 20px;
  margin-bottom: 4%;
}

.report-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  border: 2px solid rgba(0, 174, 165, 0.7);
}

.report label {
  color: var(--text-color);
  font-size: 18px;
  font-weight: bold;
}

.report button {
  margin-top: 2.5%;
  width: 45%;
  background-color: var(--secondary-color);
  margin-right: 5%;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth effects */
  min-height: 40% !important;
  text-transform: none !important;
}




.report button:hover {
  background-color: var(--quaternary-color); 
  transform: scale(1.03); 
  cursor: pointer; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

}

.report-output {
  max-width: 800px;
  margin: 40px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 4px solid rgba(0, 174, 165, 0.7);
  color: black;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.report-output img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}


.report-output h4 {
  color: black;
}

.report-output h5 {
  color: black;
  text-align: center;
}

.report .white-input {
  color: var(--text-color) !important;
  background-color: var(--bg-color-two) !important;
  margin-bottom: 0px;
  margin-top: 0px;
  min-height: 1rem !important;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  height: 4.4rem;
}

.report .white-input input {
  color: var(--text-color) !important;
  background-color: var(--bg-color-two) !important;
  max-width: 100% !important;
  overflow: hidden;
}

.report-chart {
  margin-bottom: 20px;
  margin-top: 20px;
}

.report-analysis {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  max-width: 800px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.report-content {
  overflow-y: auto;
}