.outerbox {
    background-color: var(--bg-color);
}

.pricing-component {
    font-family: Arial, sans-serif;
    color: var(--text-color);
    padding: 20px;
    background-color: var(--bg-color);
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
}

.pricing-component h1 {
    color: var(--accent-color);
    font-size: 2em;
    text-align: center;
}

.pricing-component .subtitle {
    color: var(--secondary-color);
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 20px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.pricing-table th,
.pricing-table td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid var(--tertiary-color);
}

.pricing-table th {
    background-color: var(--tertiary-color);
    color: var(--accent-color);
    font-weight: bold;
}

.pricing-table tr:nth-child(even) {
    background-color: var(--dark-grey);
}

.pricing-table tr:hover {
    background-color: var(--tertiary-color);
}

.pricing-table button {
    background-color: var(--accent-color);
    color: var(--text-color);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 64px;
}

.pricing-table button:hover {
    background-color: var(--secondary-color);
}

.pricing-table td.checkmark {
    color: #28a745;
    font-weight: bold;
}

.pricing-table td.crossmark {
    color: #dc3545;
    font-weight: bold;
}

.pricing-table td.checkmark::before {
    content: "✓";
}

.pricing-table td.crossmark::before {
    content: "✗";
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: var(--accent-color);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: var(--tertiary-color);
    color: var(--text-color);
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: var(--tertiary-color) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}