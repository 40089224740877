.account h3 {
  padding: 20px;
  margin-bottom: 4%;
  font-weight: bold;
  width: 80vw;
}

.account h1,
h2 {
  color: #2c3e50;
}

.account{
  display: flex;
  width: 75vw;
  flex-wrap: wrap;
  justify-content: center;
}
.section {
  background-color: var(--bg-color);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(233, 244, 244, 0.4);
  margin-right: 1%;
  margin-left: 1%;
  color: var(--text-color) !important;
  width: 37.5%;

  h2 {
    color: var(--text-color);
  }
}


.form-group {
  margin-bottom: 15px;
}

.account label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.account_section_heading{
  font-weight: bold !important;
  padding-top: 3%;
  padding-left: 0%;
}

.account input[type="text"] {
  width: 100%;
  height: 1.35rem;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--bg-color-two);
  color: var(--text-color);
}

.account input[type="email"] {
  max-width: 100%;
  height: 1.35rem;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--bg-color-two);
  color: var(--text-color);
}

.account input[type="password"] {
  width: 100%;
  height: 1.35rem;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--bg-color-two);
  color: var(--text-color);
}

.account button {
  background-color: var(--secondary-color);  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  font: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.account button:hover {
  background-color: var(--quaternary-color); 
  transform: scale(1.03); 
  cursor: pointer; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.cancel-button {
  background-color: #f74141 !important;
  color: #e0f7fa !important;
}

.cancel-button:hover {
  background-color: #fa7b7b !important;
  color: black !important;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.account input:checked+.slider {
  background-color: #2196F3;
}

.account input:checked+.slider:before {
  transform: translateX(26px);
}

@media (max-width: 768px) {
  .account h3 {
    padding: 8px;
    font-size: 3.5rem;
    font-weight: bold;
    margin-top: 2rem;
  }
  .account{
    display: block;
  }

  .section {
    padding: 15px;
    margin-right: 3%;
    margin-left: -3%;
    width: 87.5vw;

  }

  .form-group {
    margin-bottom: 10px;
  }

  .account button {
    padding: 6px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0.75rem;
    height: 3.75rem;
    width: 60%;
  }

  .toggle-switch {
    width: 45px;
    height: 24px;
  }

  .slider:before {
    height: 18px;
    width: 18px;
    transform: translateX(18px);
  }

  .account_section_heading {
    padding: 2%;
  }

  .account input[type="text"] {
    width: 95%;
    height: 1.5rem;
  }

  .account input[type="email"] {
    width: 95%;
    height: 1.5rem;
  }

  .account input[type="password"] {
    width: 95%;
    height: 1.5rem;
  }
}