.heatmap h3 {
	padding: 20px;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.map-box { 
  border: 3px solid rgba(0, 174, 165, 0.7);
  border-radius: 4px !important;
}

.no-data-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}