.settings h3 {
	padding: 20px;
	margin-bottom: 4%;
}

.settings-paper {
  padding: 20px;
  background-color: #212446 !important;
  border: 2px solid rgba(0, 174, 165, 0.7);
  color: #ffffff !important;
}

.settings .white-input {
  color: white !important;
  background-color: #323769 !important;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid #ddd !important;
}